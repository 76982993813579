"use client";

import React, { useState } from "react";
import * as z from "zod";

import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import useStore from "@/store/useStore";
import PlanCard from "@/components/plan-card";
import { Button } from "@/components/ui/button";
import { Textarea } from "./ui/textarea";
import { useRouter } from "next/navigation";
import axios from "axios";

async function validateUserName(nickName: string) {
  const data = { gifted_username: nickName };
  const { data: userCheckResponse } = await axios.post(
    "/api/user/check-exist",
    data,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  );

  if (userCheckResponse !== null) {
    return true;
  } else {
    return false;
  }
}

const formSchema = z.object({
  gifted_username: z
    .string()
    .min(1, { message: "Bu alan gereklidir." })
    .pipe(
      z.string().refine((val) => validateUserName(val), {
        message: "Kullanıcı veritabanımızda bulunamadı.",
      }),
    ),
  gift_message: z.string().optional(),

  gifted_user_id: z.string().min(1, { message: "Bu alan gereklidir." }),
  gifted_usermail: z.string().min(1, { message: "Bu alan gereklidir." }),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function HediyeForm({ products }: { products: any }) {
  const router = useRouter();
  const { personalInfo, setPersonalInfo, increaseStep, product, setProduct } =
    useStore((state) => state);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [giftUser, setGiftUser] = useState("");
  const [giftUserId, setGiftUserId] = useState("");
  const [giftUserMail, setGiftUserMail] = useState("");

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      gifted_username: "",
      gift_message: "",
      gifted_user_id: "",
      gifted_usermail: "",
    },
  });
  const {
    control,
    formState: { errors },
  } = form;

  const handleOnChange = async (value: string) => {
    if (value.length > 3) {
      // setGiftUserId("");
      // setGiftUserMail("");
      const data = { gifted_username: value };
      // axios call to /api/
      const { data: userCheckResponse } = await axios.post(
        "/api/user/check-exist",
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      if (userCheckResponse !== null) {
        form.setValue("gifted_user_id", `${userCheckResponse.Id}`);
        setGiftUserId(userCheckResponse.Id);

        form.setValue("gifted_usermail", `${userCheckResponse.Email}`);
        setGiftUserMail(userCheckResponse.Email);
      } else {
        setGiftUserId("");
        form.setValue("gifted_user_id", "");
        setGiftUserMail("");
        form.setValue("gifted_usermail", "");
      }
      setGiftUser(value);
    } else {
      setGiftUser(value);
    }
  };
  const onSubmit = (values: ValidationSchema) => {
    setProduct({
      ...product,
      Id: selectedPlan ? selectedPlan.Id : products[0].Id,
      Title: selectedPlan ? selectedPlan.Title : products[0].Title,
      Price: selectedPlan ? selectedPlan.Price : products[0].Price,
      Type: "giftOrder",
      Duration: selectedPlan ? selectedPlan.Duration : products[0].Duration,
      Amount: 1,
      gifted_user_id: giftUserId,
      gifted_usermail: giftUserMail,
      gifted_username: giftUser,
      gift_message: values.gift_message || "",
    });
    setProduct({ ...product, ...values });
    router.push("/premium/checkout");
  };
  const handleOnClick = (plan: any) => {
    setSelectedPlan({
      ...selectedPlan,
      id: plan.id,
      name: plan.Title,
      price: plan.Price,
      amount: 1,
    });
    setProduct({
      ...product,
      Id: plan.Id,
      Title: plan.Title,
      Price: plan.Price,
      Type: "giftOrder",
      Duration: plan.Duration,
      Amount: 1,
    });
  };
  const price = selectedPlan?.price ? selectedPlan?.price : products[0].Price;

  const totalPrice = price * 1;

  return (
    <Form {...form}>
      <form
        className="flex flex-col"
        onSubmit={() => form.handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-1 gap-8">
          <div>
            <FormField
              control={control}
              name="gifted_username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-c-primary-marine-blue flex items-center justify-between">
                    Alıcı Kullanıcı Adı
                    <FormMessage>{errors.gifted_username?.message}</FormMessage>
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className={cn(
                        "placeholder:text-c-neutral-cool-gray border-c-neutral-light-gray text-c-primary-marine-blue  placeholder:font-medium",
                        {
                          "border-c-primary-strawberry-red":
                            errors.gifted_username?.message,
                        },
                      )}
                      {...field}
                      value={giftUser}
                      onChangeCapture={(e) =>
                        handleOnChange(e.currentTarget.value)
                      }
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="gifted_user_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-c-primary-marine-blue flex hidden  items-center justify-between">
                    Alıcı Kullanıcı Id
                    <FormMessage>{errors.gifted_user_id?.message}</FormMessage>
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="hidden"
                      className={cn(
                        "placeholder:text-c-neutral-cool-gray border-c-neutral-light-gray text-c-primary-marine-blue  placeholder:font-medium",
                        {
                          "border-c-primary-strawberry-red":
                            errors.gifted_user_id?.message,
                        },
                      )}
                      {...field}
                      value={giftUserId}
                      onChangeCapture={(e) =>
                        setGiftUserId(e.currentTarget.value)
                      }
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="gifted_usermail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-c-primary-marine-blue flex hidden items-center justify-between">
                    mail
                    <FormMessage>{errors.gifted_usermail?.message}</FormMessage>
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="hidden"
                      className={cn(
                        "placeholder:text-c-neutral-cool-gray border-c-neutral-light-gray text-c-primary-marine-blue  placeholder:font-medium",
                        {
                          "border-c-primary-strawberry-red":
                            errors.gifted_usermail?.message,
                        },
                      )}
                      {...field}
                      value={giftUserMail}
                      onChangeCapture={(e) =>
                        setGiftUserMail(e.currentTarget.value)
                      }
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="gift_message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-c-primary-marine-blue flex items-center justify-between">
                    Alıcıya mesaj (istege bağlı)
                    <FormMessage>{errors.gift_message?.message}</FormMessage>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      className={cn(
                        "placeholder:text-c-neutral-cool-gray border-c-neutral-light-gray text-c-primary-marine-blue bg-white placeholder:font-medium",
                        {
                          "border-c-primary-strawberry-red":
                            errors.gift_message?.message,
                        },
                      )}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="mt-4 grid grid-cols-2 gap-4">
          {products.map((plan: any) => (
            <PlanCard
              key={`Products-${plan.Id}`}
              onClick={() => handleOnClick(plan)}
              item={plan}
            />
          ))}
        </div>
        <div className="items-between mt-8 flex justify-between">
          <div>
            <span>Toplam Fiyat:</span>
            <span className="text-2xl font-bold"> {totalPrice} TL</span>
          </div>
          <Button
            onClick={form.handleSubmit(onSubmit)}
            className=" w-40 justify-center"
          >
            Hediye Edin!
          </Button>
        </div>
      </form>
    </Form>
  );
}
