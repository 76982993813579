"use client";

import React, { useState } from "react";
import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import useStore from "@/store/useStore";
import PlanCard from "@/components/plan-card";
import { Button } from "@/components/ui/button";
import { useRouter } from "next/navigation";

const formSchema = z.object({
  amount: z.number().min(1, { message: "Kişi sayısı is required" }).max(20),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function AskidaForm({ products }: { products: any }) {
  const { personalInfo, setPersonalInfo, increaseStep, product, setProduct } =
    useStore((state) => state);

  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const router = useRouter();
  const form = useForm<ValidationSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: { amount: 1 },
  });
  const {
    control,
    formState: { errors },
  } = form;

  let [amount, setAmount] = useState(1);
  let [price, setPrice] = useState(parseInt(products[0].Price));
  let [totalPrice, setTotalPrice] = useState(0);
  const productBasketType: { [key: string]: string } = {
    1: "subscriptionOrder",
    2: "basketOrder",
    3: "basketOrder",
    4: "basketOrder",
    5: "donationOrder",
    6: "giftOrder",
  };

  const onSubmit = (values: ValidationSchema) => {
    setProduct({
      ...product,
      Id: selectedPlan ? selectedPlan.Id : products[0].Id,
      Title: selectedPlan ? selectedPlan.Title : products[0].Title,
      Price: selectedPlan ? selectedPlan.Price : products[0].Price,
      Type: "donationOrder",
      Duration: selectedPlan ? selectedPlan.Duration : products[0].Duration,
      Amount: amount ? amount : 1,
    });

    router.push("/premium/checkout");
  };

  const handleOnClick = (plan: any) => {
    setAmount(amount);

    setPrice(plan.Price);
    setTotalPrice(plan.Price * (amount ? amount : 1));

    setSelectedPlan({
      ...selectedPlan,
      Id: plan.Id,
      Title: plan.Title,
      Price: plan.Price,
      Duration: plan.Duration,
    });
    setProduct({
      ...product,
      Id: plan.Id,
      Title: plan.Title,
      Price: plan.Price,
      Type: "donationOrder",
      Amount: amount,
      Duration: plan.Duration,
    });
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseInt(e.target.value));
    setProduct({
      ...product,

      Type: "donationOrder",
      Amount: parseInt(e.target.value),
      Duration: selectedPlan.Duration,
    });

    setPrice(price);

    setTotalPrice(price * amount);
  };

  totalPrice = amount * price;

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          {products.map((plan: any) => (
            <PlanCard
              key={`Products-${plan.Id}`}
              onClick={() => handleOnClick(plan)}
              item={plan}
              selectedPlan={selectedPlan}
            />
          ))}
        </div>

        <div className="grid grid-cols-2 gap-8">
          <div>
            <FormField
              control={control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-c-primary-marine-blue flex items-center justify-between">
                    Kişi Sayısı
                    <FormMessage>{errors.amount?.message}</FormMessage>
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      className={cn(
                        "placeholder:text-c-neutral-cool-gray border-c-neutral-light-gray text-c-primary-marine-blue placeholder:font-medium",
                        {
                          "border-c-primary-strawberry-red":
                            errors.amount?.message,
                        },
                      )}
                      {...field}
                      value={amount ? amount : 1}
                      onChange={(e) => handleAmountChange(e)}
                      min="1"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="items-top mt-8 flex justify-between">
          <div className="h-30">
            <span>Toplam Fiyat:</span>
            <span className="text-2xl font-bold"> {totalPrice} TL</span>
          </div>
          <Button
            onClick={form.handleSubmit(onSubmit)}
            className=" w-40 justify-center"
          >
            Hediye Edin!
          </Button>
        </div>
      </form>
    </Form>
  );
}
